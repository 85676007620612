import { Component } from '@angular/core';

@Component({
    selector: 'app-modules-cards',
    templateUrl: './modules-cards.component.html',
    styleUrls: ['./modules-cards.component.scss'],
})
export class ModulesCardsComponent {
    topics = [
        {
            topic: 'Atopic Dermatitis',
            text: 'A condition characterised by an itchy inflammation of the skin, mostly in children.',
            icon: 'icofont-doctor',
            link: '/atopic-dermatitis',
        },
        {
            topic: 'Mental Health',
            text: 'Mental Health encompasses emotional, psychological, and social well-being.',
            icon: 'icofont-brainstorming',
            link: '/mental-health',
        },
        {
            topic: 'Cardiovascular Disease',
            text: 'Heart conditions that include diseased vessels, structural problems and blood clots.',
            icon: 'icofont-heart-beat-alt',
            link: '/cardiovascular-disease',
        },
        {
            topic: 'Respiratory',
            text: 'The respiratory system consists of specific organs and structures used for gas exchange.',
            icon: 'icofont-lungs',
            link: '/respiratory-disease',
        },
        {
            topic: 'Liver Disease',
            text: 'Liver disease refers to conditions that stop the liver from working or prevent it from functioning well.',
            icon: 'icofont-prescription',
            link: '/liver-disease',
        },
        {
            topic: 'Pain',
            text: 'Pain is an uncomfortable feeling that tells you something may be wrong.',
            icon: 'icofont-thunder-light',
            link: '/pain',
        },
        {
            topic: 'Shared decision making',
            text: 'Shared decision making is a discipline that includes digital care programs, technologies with health.',
            icon: 'icofont-computer',
            link: '/shared-decision-making',
        },
    ];

    constructor() {}
}
