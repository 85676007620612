import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { LoginComponent } from './components/pages/login/login.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { DoctorComponent } from './components/pages/doctor/doctor.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { AtopicDermatitasComponent } from './components/pages/modules/atopic-demermatitis/atopic-dermatitis.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';
import { PatientMessagesComponent } from './components/pages/patient-messages/patient-messages.component';
import { ModulesComponent } from './components/pages/modules/modules.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { ModulesCardsComponent } from './components/pages/modules/modules-cards/modules-cards.component';
import { FaqAtopicDermatitisComponent } from './components/pages/faqs/faq-atopic-dermatitis/faq-atopic-dermatitis.component';
import { FaqMentalHealthComponent } from './components/pages/faqs/faq-mental-health/faq-mental-health.component';
import { FaqHepatitisComponent } from './components/pages/faqs/faq-hepatitis/faq-hepatitis.component';
import { FaqCardiovascularComponent } from './components/pages/faqs/faq-cardiovascular/faq-cardiovascular.component';
import { FaqRespiratoryComponent } from './components/pages/faqs/faq-respiratory/faq-respiratory.component';
import { FaqOncologyComponent } from './components/pages/faqs/faq-oncology/faq-oncology.component';
import { FaqPainComponent } from './components/pages/faqs/faq-pain/faq-pain.component';
import { FaqDigitalHealthComponent } from './components/pages/faqs/faq-digital-health/faq-digital-health.component';
import { SupportGroupAtopicDermatitisComponent } from './components/pages/support-groups/support-group-atopic-dermatitis/support-group-atopic-dermatitis.component';
import { ModulesCardComponent } from './components/pages/modules/modules-cards/modules-card/modules-card/modules-card.component';
import { BeforePopupComponent } from './components/pages/modules/atopic-demermatitis/before-question/before-popup.component';
import { VideoCardComponent } from './components/pages/modules/video-card/video-card.component';
import { BannerComponent } from './components/pages/home/banner/banner.component';
import { TechnicalSupportComponent } from './components/pages/technical-support/technical-support.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';

import { environment } from 'src/environments/environment';
import { AfterQuestionVideo1PopupComponent } from './components/pages/modules/atopic-demermatitis/after-question-video-1/after-question-video-1-popup.component';
import { AfterQuestionVideo2PopupComponent } from './components/pages/modules/atopic-demermatitis/after-question-video-2/after-question-video-2-popup.component';
import { AfterQuestionVideo3PopupComponent } from './components/pages/modules/atopic-demermatitis/after-question-video-3/after-question-video-3-popup.component';
import { KnowMoreComponent } from './components/pages/about/about-know-more/know-more.component';
import { ResourcesComponent } from './components/pages/resources/resources.component';
import { ResourcesAtopicDermatitisComponent } from './components/pages/resources/resources-atopic-dermatitis/resources-atopic-dermatitis.component';
import { GoogleAnalyticsService } from './services/google-analytics';
import { SupportGroupMentalHealthComponent } from './components/pages/support-groups/support-group-mental-health/support-group-mental-health.component';
import { MentalHealthComponent } from './components/pages/modules/mental-health/mental-health.component';
import { AfterQuestionVideo1PopupMHComponent } from './components/pages/modules/mental-health/after-question-video-1/after-question-video-1-popup.component';
import { BeforePopupMHComponent } from './components/pages/modules/mental-health/before-question/before-popup.component';
import { AfterQuestionVideo2PopupMHComponent } from './components/pages/modules/mental-health/after-question-video-2/after-question-video-2-popup.component';
import { AfterQuestionVideo3PopupMHComponent } from './components/pages/modules/mental-health/after-question-video-3/after-question-video-3-popup.component';
import { ResourcesMentalHealthComponent } from './components/pages/resources/resources-mental-health/resources-mental-health.component';
import { CardioVascularDiseaseComponent } from './components/pages/modules/cardiovascular-disease/cardiovascular-disease.component';
import { SupportGroupCardiovascularDiseaseComponent } from './components/pages/support-groups/support-group-cardiovascular-disease/support-group-cardiovascular-disease.component';
import { ResourcesCardiovascularDiseaseComponent } from './components/pages/resources/resources-cardiovasular-disease/resources-cardiovascular-disease.component';
import { CardioVascularDiseaseChinaComponent } from './components/pages/modules/cardiovascular-disease-china/cardiovascular-disease-china.component';
import { RespiratoryDiseaseComponent } from './components/pages/modules/respiratory-disease/respiratory-disease.component';
import { ResourcesRespiratoryDiseaseComponent } from './components/pages/resources/resources-respiratory-disease/resources-respiratory-disease.component';
import { SupportGroupRespiratoryComponent } from './components/pages/support-groups/support-group-respiratory/support-group-respiratory.component';
import { LanguageSelectorComponent } from './components/language/language-selector.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageGuard } from './guards/language.gard';
import { PatientMessageCardComponent } from './components/pages/patient-messages/patient-message-card/patient-message-card.component';
import { LiverDiseaseComponent } from './components/pages/modules/liver-disease/liver-disease.component';
import { ResourcesLiverDiseaseComponent } from './components/pages/resources/resources-liver-disease/resources-liver-disease.component';
import { SupportGroupLiverDiseaseComponent } from './components/pages/support-groups/support-group-liver-disease/support-group-liver-disease.component';
import { FaqLiverDiseaseComponent } from './components/pages/faqs/faq-liver-disease/faq-liver-disease.component';
import { PainComponent } from './components/pages/modules/pain/pain.component';
import { ResourcesPainComponent } from './components/pages/resources/resources-pain/resources-pain.component';
import { SupportGroupPainComponent } from './components/pages/support-groups/support-group-pain/support-group-pain.component';
import { SharedDecisionMakingComponent } from './components/pages/modules/shared-decision-making/shared-decision-making.component';
import { AppLinkComponent } from './components/pages/app/app-link.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LanguageSelectorComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        HomeComponent,
        AboutComponent,
        TechnicalSupportComponent,
        TestimonialsComponent,
        SignupComponent,
        LoginComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        FaqAtopicDermatitisComponent,
        FaqMentalHealthComponent,
        FaqHepatitisComponent,
        FaqCardiovascularComponent,
        FaqRespiratoryComponent,
        FaqOncologyComponent,
        FaqPainComponent,
        FaqDigitalHealthComponent,
        FaqLiverDiseaseComponent,
        ErrorComponent,
        ServicesComponent,
        ServicesDetailsComponent,
        DoctorDetailsComponent,
        DoctorComponent,
        ResourcesComponent,
        ResourcesAtopicDermatitisComponent,
        ResourcesMentalHealthComponent,
        ResourcesCardiovascularDiseaseComponent,
        ResourcesRespiratoryDiseaseComponent,
        ResourcesLiverDiseaseComponent,
        ResourcesPainComponent,
        BlogDetailsComponent,
        ContactComponent,
        ComingSoonComponent,
        AtopicDermatitasComponent,
        MentalHealthComponent,
        PatientMessagesComponent,
        ModulesComponent,
        ModulesCardsComponent,
        ModulesCardComponent,
        SupportGroupAtopicDermatitisComponent,
        SupportGroupMentalHealthComponent,
        SupportGroupCardiovascularDiseaseComponent,
        SupportGroupRespiratoryComponent,
        SupportGroupLiverDiseaseComponent,
        SupportGroupPainComponent,
        BeforePopupComponent,
        AfterQuestionVideo1PopupComponent,
        AfterQuestionVideo2PopupComponent,
        AfterQuestionVideo3PopupComponent,
        BeforePopupMHComponent,
        AfterQuestionVideo1PopupMHComponent,
        AfterQuestionVideo2PopupMHComponent,
        AfterQuestionVideo3PopupMHComponent,
        VideoCardComponent,
        BannerComponent,
        KnowMoreComponent,
        CardioVascularDiseaseComponent,
        CardioVascularDiseaseChinaComponent,
        RespiratoryDiseaseComponent,
        PatientMessageCardComponent,
        LiverDiseaseComponent,
        PainComponent,
        SharedDecisionMakingComponent,
        AppLinkComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        PdfViewerModule,
        MatRadioModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleChartsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
    ],
    providers: [GoogleAnalyticsService, LanguageGuard],
    bootstrap: [AppComponent],
})
export class AppModule {}
