import { Component } from '@angular/core';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-shared-decision-making',
    templateUrl: './shared-decision-making.component.html',
    styleUrls: ['./shared-decision-making.component.scss'],
})
export class SharedDecisionMakingComponent {
    constructor() {}

    videos: Video[] = [
        {
            topic: 'Patient Safety',
            text: '',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.09',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/shared-decision-making%2FPATIENT%20SAFETY%20VIDEO%2031May23.mp4?alt=media&token=dff18944-bcfa-4c74-a122-6aeac5a1db15',
            poster: 'assets/img/banner/modules.jpg',
            videoNumber: 1,
        },
        // {
        //     topic: 'Dr. Naim Aoun',
        //     text: 'Dr Naim Aoun helps you get a better understanding of COPD in the Middle East',
        //     bullets: [
        //         'Click the play button on the bottom left to continue',
        //         'Click on the square icon to view in full screen',
        //     ],
        //     videoLength: '7.27',
        //     url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/respiratory%2FDR%20NAIM%20PRESENTATION%20V.2%2020April23.mp4?alt=media&token=8626730d-f7b9-4ae0-bd1c-ecc36533dc01',
        //     poster: 'assets/img/banner/respiratory-video-2.jpg',
        //     videoNumber: 1,
        // },
    ];
}
